import React, { Component } from "react";
 

class ContactLocation extends Component {
  render() {
 
    return (
      <>
        <div className="w-full">
          <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2359.4692824886374!2d80.43961601886635!3d5.9756551892499035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNcKwNTgnMzEuMCJOIDgwwrAyNicyMy40IkU!5e0!3m2!1sen!2slk!4v1700993671529!5m2!1sen!2slk" 
           
          height="350" 
          style={{border:"0"}}
          allowfullscreen="" 
          loading="lazy" 
          title="cozybusy"
          referrerpolicy="no-referrer-when-downgrade"
          className="w-full"></iframe>

        </div>
        
      </>

    );
  }
};


export default  ContactLocation;
