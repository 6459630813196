import React from 'react'
import Logo from '../assets/Logo_White.png'
 
 

const HomeItem1 = () => {

    return (
        <> 
       
        
        <div className="flex flex-col  justify-between mx-auto  w-full bg-[#02020293] lg:p-28 md:p-12 p-6 h-[70vh]" >

            <div className='flex items-center justify-center w-full h-full'>

                <img src={Logo} alt='/' className='w-[700px] logo'/>               

            </div>

        </div>
        </>
    )
}

export default HomeItem1
