import React from 'react'
import Image from '../assets/become a Vendor.jpg'
import { Popover } from 'antd';

const HomeItem7 = () => {
    return (
        <div className='flex flex-col px-6 py-6 bg-white md:py-20 lg:px-28 md:px-12 lg:flex-row'>

            {/*  */}
            <div className='lg:w-[50%] w-full h-60 lg:h-full overflow-hidden md:h-80 mt-10 md:mt-0'>
                <img src={Image} alt="/" />
            </div>

            {/*  */}
            <div className='lg:w-[50%] lg:px-10 lg:pl-10 flex flex-col justify-between'>
                <h1 className='py-3 text-3xl md:pb-6 lg:text-5xl paraFont3'>Ready to <span className='paraFont4 text-[#8CC63F]'>START</span> <br />become a Vender ?</h1>
                <p className='text-sm lg:pb-6 lg:text-base md:pb-3'>Elevate your business to new heights by becoming a valued vendor on our platform.
                    Join us at COZYBUSY™  and unlock opportunities to enhance your value chain, reduce costs, improve quality, and innovate - all while reaching a broader customer base. Register now; and your journey to business success begins here!
                </p>

                <div className='flex justify-end'>
                    <Popover content="You can sign up!">
                        <h1 className='font-medium cursor-pointer lg:text-2xl text-end md:text-xl w-[120px]'>Click Here</h1>
                    </Popover>
                </div>
            </div>

        </div>
    )
}

export default HomeItem7
