import React from 'react'

import Agristuff from '../assets/partners/agristuff.jpg'
import Ahk from '../assets/partners/ahk.png'
import Capceylon from '../assets/partners/capceylon.png'
import Cocolab from '../assets/partners/cocolab.png'
import Orbit from '../assets/partners/orbit.jpg'
import Orgico from '../assets/partners/orgico.jpg'

import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const HomeItem3 = () => {

  const [ref,inView] = useInView({
    threshold:0.5,
  });

  return (
    <div className='px-6 pb-10 bg-white md:px-12 lg:px-0'>
      <div className=''>
        <div className="flex flex-col items-center pt-6 md:py-6">

          <div className="py-3 text-2xl md:text-4xl">
            <span>Our Partners & Members</span>
          </div>
          <div className="text-sm text-center md:text-lg">
            <p>We are proud to collaborate with a diverse and dedicated group of partners and members who share our vision for innovation and excellence.
            </p>
          </div>
        </div>

        {/*  */}
        <div className='grid grid-cols-2 gap-3 mt-4 mb-10 md:grid-cols-4 lg:gap-24 md:mx-12 lg:mx-20 md:gap-10' ref={ref}>

          <div className='flex flex-col items-center justify-center gap-2 py-3 shadow-md'>
            <h1 className='text-lg font-bold'>{
                    inView ?
                    <CountUp start={0} end={150} duration={3}/> : null}+</h1>
            <span className='uppercase'>Products</span>
          </div>
          <div className='flex flex-col items-center justify-center gap-2 py-3 shadow-md'>
            <h1 className='text-lg font-bold'>{
                    inView ?
                    <CountUp start={0} end={15} duration={2}/> : null}+</h1>
            <span className='uppercase'>vendors</span>
          </div>
          <div className='flex flex-col items-center justify-center gap-2 py-3 shadow-md'>
            <h1 className='text-lg font-bold'>{
                    inView ?
                    <CountUp start={0} end={7} duration={2}/> : null}+</h1>
            <span className='uppercase'>franchisees</span>
          </div>
          <div className='flex flex-col items-center justify-center gap-2 py-3 shadow-md'>
            <h1 className='text-lg font-bold'>{
                    inView ?
                    <CountUp start={0} end={4} duration={2}/> : null}+</h1>
            <span className='uppercase'>global shops</span>
          </div>
          
        </div>

        {/*  */}
        <div className='grid lg:grid-cols-6 border-[1px] md:mx-12 lg:mx-20 lg:gap-24 md:gap-x-24 grid-cols-3 gap-x-12 shadow-lg'>
          <div className='flex items-center justify-center'>
            <img src={Agristuff} alt='/'/>
          </div>
          <div className='flex items-center justify-center'>
            <img src={Ahk} alt='/'/>
          </div>
          <div className='flex items-center justify-center'>
            <img src={Capceylon} alt='/'/>
          </div>
          <div className='flex items-center justify-center'>
            <img src={Cocolab} alt='/'/>
          </div>
          <div className='flex items-center justify-center'>
            <img src={Orbit} alt='/'/>
          </div>
          <div className='flex items-center justify-center'>
            <img src={Orgico} alt='/'/>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default HomeItem3
