import React from 'react';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { NavLink } from 'react-router-dom';
import CookiePolicyNotification from '../components/CookiePolicyNotification';
import { ArrowUpOutlined } from '@ant-design/icons';

const Cookies = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    };

    return (
        <>

            <div className='fixed z-50 md:bottom-5 md:right-5 right-2 bottom-2'>
                <button onClick={scrollToTop} className='bg-[#9CCD58] rounded-full shadow-xl w-10 h-10 active:scale-105'><ArrowUpOutlined size={30} className='text-white' /> </button>
            </div>

            <Navbar />
            <div className='flex justify-center w-full h-full '>

                <div className=' w-[900px] flex flex-col text-start gap-5  md:p-10 shadow-xl p-5 my-5'>
                    <h1 className='text-xl font-medium'>About cookies</h1>
                    <div className='flex flex-col gap-4'>
                        <p>1.1 A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server
                            to a web browser and is stored by the browser. The identifier is then sent back to the server each time the
                            browser requests a page from the server.</p>
                        <p>1.2 Cookies may be either “persistent” cookies or “session” cookies: a persistent cookie will be stored by
                            a web browser and will remain valid until its set expiry date, unless deleted by the user before the
                            expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web
                            browser is closed.</p>
                        <p>1.3 Cookies do not typically contain any information that personally identifies a user, but personal
                            information that we store about you may be linked to the information stored in and obtained from cookies.</p>
                    </div>

                    <h1 className='mt-5 text-lg font-medium'>Cookies that we use</h1>
                    <div className='flex flex-col gap-4'>
                        <p>2.1 We use cookies for the following purposes:</p>
                        <p className='text-sm'>(a)	Whether your browser can accept cookies –Our website may write a test cookie to your browser to check
                            that it can accept cookies for the purposes of logging in. We do not currently host any services on our
                            website that require you to login may however still write this cookie under certain circumstances. </p>
                        <p className='text-sm'>(b) whether you have dismissed the cookie notice banner at the foot of the site – we write a cookie to
                            your browser when you have dismissed this notice to ensure that we do not display it again. This cookie
                            expires 1 month after being set. The relevant cookie is: cookie_notice_accepted.</p>
                    </div>

                    <h1 className='mt-5 text-lg font-medium'>Cookies used by our service providers</h1>
                    <div className='flex flex-col gap-4'>
                        <p>3.1 Our service providers use cookies and those cookies may be stored on your computer when you visit our website.
                            For example, like many companies, we use analytics to help us monitor our traffic, to get a sense of how many visitors
                            the Website receives every day, the type of individual visiting the website and which sections of the site users typically
                            look at. It’s a key way of knowing how well we’re doing and what we can do better.</p>
                        <p>3.2 We use Google Analytics to analyse the use of our website. Google Analytics gathers information about website use by
                            means of cookies. The information gathered relating to our website is used to create reports about the use of our website.
                            Google’s privacy policy is available at: https://www.google.com/policies/privacy/. The relevant cookies
                            are: _ga, _gid & _gat_gtag_UA_4666434_1</p>
                        <p>3.3 We pay for advertising from marketing partners on other sites, for example Facebook/LinkedIn/Instragram.
                            These third parties sometimes require that we put a tiny image (“pixel”) from their site on any landing pages
                            that users might arrive at. These pixels can create cookies for the third party so we can work with them to
                            understand how successful their marketing campaign was. We also need to understand where a user came from, so that,
                            if the user goes on to sign up on our Website, we can pay the correct marketing partner for their service. This website
                            uses retargeting services from the social networks Facebook and LinkedIn. Facebook and LinkedIn collect certain
                            information via cookies to determine which web pages are visited. This data is then used to associate your browser
                            with interest and demographic categories, and serve Facebook and LinkedIn ads based on your past visits to this website.
                            Please note that any information collected by Facebook and LinkedIn via cookies are not linked to any customer’s personal
                            information collected by us.</p>
                    </div>

                    <h1 className='mt-5 text-lg font-medium'>Managing cookies</h1>
                    <div className='flex flex-col gap-4'>
                        <p>4.1 Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser
                            to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:</p>
                        <div className='flex flex-col gap-2'>
                            <p className='text-sm text-blue-500 cursor-pointer'>(a) https://support.google.com/chrome/answer/95647?hl=en (Chrome);</p>
                            <p className='text-sm text-blue-500 cursor-pointer'>(b)https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences (Firefox);</p>
                            <p className='text-sm text-blue-500 cursor-pointer'>(c) http://www.opera.com/help/tutorials/security/cookies/ (Opera);</p>
                            <p className='text-sm text-blue-500 cursor-pointer'>(d) https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-co okies (Internet Explorer);</p>
                            <p className='text-sm text-blue-500 cursor-pointer'>(e) https://support.apple.com/kb/PH21411 (Safari); and</p>
                            <p className='text-sm text-blue-500 cursor-pointer'>(f) https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy (Edge).</p>
                        </div>
                        <p>4.2 Blocking all cookies will have a negative impact upon the usability of many websites.</p>
                        <p>4.3 If you block cookies, you may not be able to use all the features on our website.You may exercise any of your rights in
                            relation to your personal data by written notice to us.</p>
                    </div>


                    <h1 className='mt-5 text-lg font-medium'>Our details</h1>
                    <div className='flex flex-col gap-4'>
                        <p>5.1 This website is owned and operated by Cozybusy(Pvt)Ltd.</p>
                        <p>5.2 We are registered in under the Companies Act No. 7 of 2007 and registration number PV 00267580, and our registered office is at: 90,Gangarama Mw, Pelena, Weligama, Sri Lanka. </p>
                        <p>5.3  You can contact us:</p>
                        <div className='flex flex-col gap-2'>
                            <p className='text-sm'>(a) by post, using the postal address given above;</p>
                            <p className='text-sm'>(b) using our website contact form;</p>
                            <p className='text-sm'>(c) by telephone, on +94 113483727 or</p>
                            <p className='text-sm'>(d) by email, using info@cozybusy.com</p>
                        </div>
                    </div>

                    <div className='flex justify-end w-full gap-6 my-5'>


                        <NavLink
                            exact
                            to="/">
                            <button className="px-4 py-2 font-bold   rounded outline outline-[#c63f3f] hover:bg-[#c63f3f] duration-300 hover:text-black">
                                Not Now
                            </button>
                        </NavLink>
                    </div>

                </div>

            </div>

            <CookiePolicyNotification />
            <Footer />
        </>
    )
}

export default Cookies
