import React from 'react'
import Img1 from "../assets/catelog/Process-food---564-x-564.jpg";
import Img2 from "../assets/catelog/Beverage-food---564-x-564.jpg";
import Img3 from "../assets/catelog/Spices-&-essential-oil---700-x-700.jpg";
import Img4 from "../assets/catelog/Coconut-product.jpg";
import Img5 from "../assets/catelog/Organic-suppliment---(neww).jpg";
import Img6 from "../assets/catelog/Organic-suppliment-(mobile)---1200-x-565.jpg";

const HomeItem2 = () => {
    return (
        <div className='bg-[#02020293] -pt-10'>

            <div className='flex flex-col gap-5 lg:px-20 md:px-12 pt-6  -mb-20 bg-white   border-[1px]  px-6'>

                <div className='px-2 py-5 text-center shadow-lg'>
                    <h1 className='md:text-3xl font-bold paraFont5 text-[#8cc63f] text-2xl'>Local Organic Exporter </h1>
                    <h1 className='py-3 text-xl md:text-4xl'>We bring the solution to make purchasing decisions</h1>
                    <p className='pb-4 paraFont2'>that have a positive, social, environmental, and economic impact - without compromising on style, quality, and comfort.
                    </p>

                    <span className='text-lg font-bold md:text-xl paraFont2'>We believe that the “COZYBUSY" mission will help our suppliers, vendors, buyers, and consumers productivity decisions</span>
                </div>
                 
            </div>
            <div className='pt-32 pb-20 bg-white'>
                {/*  */}
                <div className='px-6 py-10 lg:px-20 md:px-12'>
                    <h1 className='uppercase tracking-[5px] text-3xl font-bold'>Catalogue</h1>
                </div>

                {/*  */}
                <div className='grid grid-cols-1 gap-1'>
                    <div className='grid grid-cols-1 gap-1 px-6 md:grid-cols-2 lg:px-20 md:px-12'>
                        <div className='grid grid-cols-1 gap-1'>
                            <div className='grid grid-cols-1 gap-1 md:grid-cols-2'>

                                {/* 1 */}
                                <div className=''>
                                    <div className="relative overflow-hidden border-2 group border-white/50 lg:h-[300px] md:h-[200px] h-[250px]">
                                        <div className="absolute z-40 flex items-center justify-center w-full h-full gap-3 transition-all duration-300 md:flex-col group-hover:bg-black/70 bg-black/50 md:gap-0">
                                            <h1 className="text-xl text-white duration-500 lg:text-2xl lg:group-hover:text-xl group-hover:text-lg">PROCESS </h1>
                                            <h1 className="text-xl text-white duration-500 lg:text-2xl lg:group-hover:text-xl group-hover:text-lg">FOODS</h1>
                                        </div>
                                        <img
                                            className="w-full h-full transition-all duration-500 group-hover:scale-125"
                                            src={Img1}
                                            alt=""
                                        />


                                        <button className="absolute z-50 flex items-center justify-center px-6 py-1 mx-auto text-white transition-all duration-500 border-2 -bottom-full lg:left-10 lg:right-10 lg:group-hover:bottom-14 hover:bg-white hover:text-black md:group-hover:bottom-6 md:left-5 md:right-5 group-hover:bottom-10 left-16 right-16">
                                            See more

                                        </button>
                                    </div>
                                </div>

                                {/* 2 */}
                                <div>
                                    <div className="relative overflow-hidden border-2 group border-white/50 lg:h-[300px] md:h-[200px] h-[250px]">
                                        <div className="absolute z-40 flex items-center justify-center w-full h-full gap-3 transition-all duration-300 md:flex-col group-hover:bg-black/70 bg-black/50 md:gap-0">
                                            <h1 className="text-xl text-white duration-500 lg:text-2xl lg:group-hover:text-xl group-hover:text-lg">BEVERAGE</h1>
                                            <h1 className="text-xl text-white duration-500 lg:text-2xl lg:group-hover:text-xl group-hover:text-lg">PRODUCTS</h1>
                                        </div>
                                        <img
                                            className="w-full h-full transition-all duration-500 group-hover:scale-125"
                                            src={Img2}
                                            alt=""
                                        />


                                        <button className="absolute z-50 flex items-center justify-center px-6 py-1 mx-auto text-white transition-all duration-500 border-2 -bottom-full lg:left-10 lg:right-10 lg:group-hover:bottom-14 hover:bg-white hover:text-black md:group-hover:bottom-6 md:left-5 md:right-5 group-hover:bottom-10 left-16 right-16">
                                            See more

                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* 3 */}
                            <div>
                                <div className="relative overflow-hidden border-2 group border-white/50 lg:h-[400px] md:h-[300px] h-[250px]">
                                    <div className="absolute z-40 flex items-center justify-center w-full h-full gap-2 transition-all duration-300 md:flex-col group-hover:bg-black/70 bg-black/50 md:gap-0">
                                        <h1 className="text-xl text-white duration-500 lg:text-4xl lg:group-hover:text-3xl md:text-3xl md:group-hover:text-2xl group-hover:text-lg">SPICES &</h1>
                                        <h1 className="text-xl text-white duration-500 lg:text-4xl lg:group-hover:text-3xl md:text-3xl md:group-hover:text-2xl group-hover:text-lg">ESSENTIAL OIL</h1>
                                    </div>
                                    <img
                                        className="w-full h-full transition-all duration-500 group-hover:scale-125"
                                        src={Img3}
                                        alt=""
                                    />


                                    <button className="absolute z-50 flex items-center justify-center px-6 py-1 mx-auto text-white transition-all duration-500 border-2 md:py-2 -bottom-full lg:left-28 lg:right-28 lg:group-hover:bottom-20 md:group-hover:bottom-14 hover:bg-white hover:text-black md:left-16 md:right-16 group-hover:bottom-10 left-16 right-16">
                                        See more

                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* 4 */}
                        <div className=''>
                            <div className="relative overflow-hidden border-2 group border-white/50 lg:h-[704px] md:h-[504px] h-[250px]">
                                <div className="absolute z-40 flex items-center justify-center w-full h-full gap-3 transition-all duration-300 md:flex-col group-hover:bg-black/70 bg-black/50 md:gap-0">
                                    <h1 className="text-xl text-white duration-500 md:text-4xl md:group-hover:text-3xl group-hover:text-lg">COCONUT</h1>
                                    <h1 className="text-xl text-white duration-500 md:text-4xl md:group-hover:text-3xl group-hover:text-lg">PRODUCT</h1>
                                </div>
                                <img
                                    className="w-full h-full transition-all duration-500 group-hover:scale-125"
                                    src={Img4}
                                    alt=""
                                />


                                <button className="absolute z-50 flex items-center justify-center px-6 py-1 mx-auto text-white transition-all duration-500 border-2 md:py-2 -bottom-full lg:left-28 lg:right-28 lg:group-hover:bottom-56 hover:bg-white hover:text-black md:group-hover:bottom-40 md:left-16 md:right-16 group-hover:bottom-10 left-16 right-16">
                                    See more

                                </button>
                            </div>
                        </div>
                    </div>

                    {/* 5 */}
                    <div className='px-6 lg:px-20 md:px-12'>
                        <div className="relative overflow-hidden border-2 group border-white/50 md:h-[300px] h-[250px]">
                            <div className="absolute z-40 flex items-center justify-center w-full h-full gap-3 transition-all duration-300 group-hover:bg-black/70 bg-black/50">
                                <h1 className="text-xl text-white duration-500 md:text-4xl md:group-hover:text-3xl group-hover:text-lg">ORGANIC</h1>
                                <h1 className="text-xl text-white duration-500 md:text-4xl md:group-hover:text-3xl group-hover:text-lg">SUPPLIMENTS</h1>
                            </div>
                            <img
                                className="hidden w-full h-full transition-all duration-500 group-hover:scale-125 md:flex"
                                src={Img5}
                                alt=""
                            />

<img
                                className="flex w-full h-full transition-all duration-500 md:hidden group-hover:scale-125"
                                src={Img6}
                                alt=""
                            />


                            <button className="absolute z-50 flex items-center justify-center px-6 py-1 mx-auto text-white transition-all duration-500 border-2 md:py-2 -bottom-full md:left-60 md:right-60 lg:group-hover:bottom-16 hover:bg-white hover:text-black md:group-hover:bottom-20 lg:left-72 lg:right-72 group-hover:bottom-10 left-16 right-16">
                                See more

                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default HomeItem2
