import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import CookiePolicyNotification from '../components/CookiePolicyNotification'
import { ArrowUpOutlined } from '@ant-design/icons';

const PrivacyPolicy = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    };

    return (
        <>

            <div className='fixed z-50 md:bottom-5 md:right-5 right-2 bottom-2'>
                <button onClick={scrollToTop} className='bg-[#9CCD58] rounded-full shadow-xl w-10 h-10 active:scale-105'><ArrowUpOutlined size={30} className='text-white' /> </button>
            </div>

            <Navbar />

            <div className='flex justify-center w-full h-full '>
                <div className=' w-[900px] flex flex-col text-start gap-6  md:p-10 shadow-xl p-5 my-5'>
                    <h1 className='text-xl font-medium'>Privacy Policy</h1>
                    <div className='flex flex-col gap-5'>
                        <p>1.1 We are committed to safeguarding the privacy of visitors to our website; in this policy,
                            we explain how we will handle your personal data.</p>
                        <p>1.2 By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.</p>
                    </div>

                    <h1 className='mt-5 text-lg font-medium'>3. How we use your personal data</h1>
                    <div className='flex flex-col gap-5'>
                        <p>3.1 In this Section 3 we have set out:</p>
                        <div className='flex flex-col gap-3'>
                            <p className='text-sm'>(a) the general categories of personal data that we may process;</p>
                            <p className='text-sm'>(b) the purposes for which we may process personal data; and</p>
                            <p className='text-sm'>(c) the legal bases of the processing.</p>
                        </div>
                        <p>3.2 We may process data about your use of our website (“usage data“). The usage data may include your IP address,
                            geographical location, browser type and version, operating system, referral source, length of visit, page views
                            and website navigation paths, as well as information about the timing, frequency and pattern of your service use.
                            The source of the usage data is Google Analytics. This usage data may be processed for the purposes of analyzing
                            the use of the website and services. The legal basis for this processing is our legitimate interests, namely
                            monitoring and improving our website and services.</p>
                        <p>3.3 We may process your account data (“account data“). The account data may include your name, Telephone numbers
                            and email address. The source of the account data is you or your employer. The account data may be processed for
                            the purposes of providing our services and communicating with you. The legal basis for this processing is our
                            legitimate interests, namely the performance of a contract between you and us and/or taking steps, at your request,
                            to enter into such a contract.</p>
                        <p>3.4 We may process information contained in any enquiry you submit to us regarding products and/or services
                            (“enquiry data“). The enquiry data may be processed for the purposes of offering, marketing and selling relevant
                            products and/or services to you. The legal basis for this processing is consent.</p>
                        <p>3.5 We may process information that you provide to us for the purpose of subscribing to our email notifications
                            and/or newsletters (“notification data“). The notification data may be processed for the purposes of sending you
                            the relevant notifications and/or newsletters. The legal basis for this processing is consent.</p>
                        <p>3.6 In addition to the specific purposes for which we may process your personal data set out in this Section 3,
                            we may also process any of your personal data where such processing is necessary for compliance with a legal
                            obligation to which we are subject.</p>
                        <p>3.7 Please do not supply any other person’s personal data to us, unless we prompt you to do so.</p>
                    </div>

                    <h1 className='mt-5 text-lg font-medium'>4. Providing your personal data to others</h1>
                    <div className='flex flex-col gap-5'>
                        <p>4.1 We may disclose your personal data to our insurers and/or professional advisers insofar as reasonably necessary
                            for the purposes of obtaining and maintaining insurance coverage, managing risks, obtaining professional advice
                            and managing legal disputes.</p>
                        <p>4.2 In addition to the specific disclosures of personal data set out in this Section 4, we may also disclose your
                            personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject,
                            or in order to protect your vital interests or the vital interests of another natural person.</p>
                    </div>

                    <h1 className='mt-5 text-lg font-medium'>5. International transfers of your personal data</h1>
                    <div className='flex flex-col gap-5'>
                        <p>5.1 In this Section 5, we provide information about the circumstances in which your personal data may be transferred
                            to countries outside the European Economic Area (EEA).</p>

                        <p>5.2 Should you subscribe to our newsletter, your name and email address will be transferred to Marketing program
                            and monitor for processing. </p>
                        <p>We use your data to send you our newsletter(s). We also gather statistics around email opening and clicks using industry
                            standard technologies to help us monitor and improve our e-newsletter.</p>
                        <p>Where we rely on your consent to our processing your personal data for sending you the newsletter(s), you have
                            the right to withdraw your consent. Where we rely on our legitimate interest, you can object to the processing of
                            your personal data for this purpose at any time. If at any point you want to withdraw your consent or object to
                            the processing, depending on the type of newsletter, you should either follow the link by clicking the “unsubscribe”
                            button or send us an email. </p>
                        <p>If you do that, we will update our records immediately to reflect your wishes</p>
                        <p><p>If you do that, we will update our records immediately to reflect your wishes</p></p>
                    </div>


                    <h1 className='mt-5 text-lg font-medium'> </h1>
                    <div className='flex flex-col gap-5'>
                        <p>6.1 This Section 6 sets out our data retention policies and procedure, which are designed to help ensure that we comply
                            with our legal obligations in relation to the retention and deletion of personal data.</p>
                        <p>6.2 Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that
                            purpose or those purposes</p>
                        <p>6.3 We will retain and delete your personal data as follows:</p>
                        <div className='flex flex-col gap-3'>
                            <p className='text-sm'>(a) Account Data will be retained for the duration of our commercial or professional relationship
                                with you or your business. Following the end of any such relationship, we are required to retain this data for a period
                                of at least 5 years to comply with current SL tax legislation.</p>
                            <p className='text-sm'>(b) Enquiry data will be retained for a maximum of 18 months after the date of last contact with
                                you in the instance that we have not subsequently entered into a contract as a result of your enquiry.</p>
                            <p className='text-sm'>(c) Notification Data is retained for as long as you decide to remain subscribed to such services.</p>
                        </div>
                        <p>6.4 Notwithstanding the other provisions of this Section 6, we may retain your personal data where such retention is necessary
                            for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital
                            interests of another natural person.</p>
                    </div>



                    <h1 className='mt-5 text-lg font-medium'>Amendments</h1>
                    <div className='flex flex-col gap-5'>
                        <p>7.1 We may update this policy from time to time by publishing a new version on our website.</p>
                        <p>7.2 You should check this page occasionally to ensure you are happy with any changes to this policy.</p>
                    </div>

                    <h1 className='mt-5 text-lg font-medium'>Your rights</h1>
                    <div className='flex flex-col gap-5'>
                        <p>8.1 In this Section 8, we have summarized the rights that you have under data protection law. Some of the
                            rights are complex, and not all of the details have been included in our summaries. Accordingly, you should
                            read the relevant laws and guidance from the regulatory authorities for a full explanation of these rights.</p>
                        <p>8.2 Your principal rights under data protection law are:</p>
                        <p>the right to access; the right to rectification; the right to erasure;the right to restrict processing; the right
                            to object to processing;the right to data portability;the right to complain to a supervisory authority;and the right to withdraw consent.</p>
                        <p>8.3 You have the right to confirmation as to whether or not we process your personal data and, where we do, access to the
                            personal data, together with certain additional information.</p>
                        <p>That additional information includes details of the purposes of the processing, the categories of personal data concerned and the recipients
                            of the personal data. Providing the rights and freedoms of others are not affected, we will supply to you a copy of your personal data. The
                            first copy will be provided free of charge, but additional copies may be subject to a reasonable fee</p>
                        <p>8.4 You have the right to have any inaccurate personal data about you rectified and, taking into account the purposes of the processing, to
                            have any incomplete personal data about you completed.</p>
                        <p>8.5 In some circumstances you have the right to the erasure of your personal data without undue delay. Those circumstances include: the
                            personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed; you withdraw
                            consent to consent-based processing; the processing is for direct marketing purposes; and the personal data have been unlawfully processed.
                            However, there are certain general exclusions of the right to erasure. Those general exclusions include where processing is necessary:
                            for compliance with a legal obligation or for the establishment, exercise or defence of legal claims.</p>
                        <p>8.6 In some circumstances you have the right to restrict the processing of your personal data. Those circumstances are: you contest the
                            accuracy of the personal data; processing is unlawful but you oppose erasure; we no longer need the personal data for the purposes
                            of our processing, but you require personal data for the establishment, exercise or defence of legal claims; and you have objected
                            to processing, pending the verification of that objection. Where processing has been restricted on this basis, we may continue to
                            store your personal data. However, we will only otherwise process it: with your consent; for the establishment, exercise or defense
                            of legal claims; for the protection of the rights of another natural or legal person; or for reasons of important public interest.</p>
                        <p>8.7 You have the right to object to our processing of your personal data on grounds relating to your particular situation, but only
                            to the extent that the legal basis for the processing is that the processing is necessary for: the performance of a task carried out
                            in the public interest or in the exercise of any official authority vested in us; or the purposes of the legitimate interests pursued by
                            us or by a third party. If you make such an objection, we will cease to process the personal information unless we can demonstrate
                            compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing is for the
                            establishment, exercise or defense of legal claims.</p>
                        <p>8.8 You have the right to object to our processing of your personal data for direct marketing purposes (including profiling for
                            direct marketing purposes). If you make such an objection, we will cease to process your personal data for this purpose.</p>
                        <p>8.9 You have the right to object to our processing of your personal data for scientific or historical research purposes or
                            statistical purposes on grounds relating to your particular situation, unless the processing is necessary for the performance
                            of a task carried out for reasons of public interest.</p>
                        <p>8.10 To the extent that the legal basis for our processing of your personal data is consent, and such processing is carried out
                            by automated means, you have the right to receive your personal data from us in a structured, commonly used and machine-readable
                            format. However, this right does not apply where it would adversely affect the rights and freedoms of others.</p>
                        <p>8.11 If you consider that our processing of your personal information infringes data protection laws, you have a legal right to
                            lodge a complaint with a supervisory authority responsible for data protection. You may do so in the EU member state of your
                            habitual residence, your place of work or the place of the alleged infringement.</p>
                        <p>8.12 To the extent that the legal basis for our processing of your personal information is consent, you have the right to
                            withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal.</p>
                        <p>8.13 You may exercise any of your rights in relation to your personal data by written notice to us.</p>
                    </div>

                    <h1 className='mt-5 text-lg font-medium'>Our details</h1>
                    <div className='flex flex-col gap-5'>
                        <p>9.1 This website is owned and operated by Cozybusy(Pvt)Ltd.</p>
                        <p>9.2 We are registered in under the Companies Act No. 7 of 2007 and registration number PV 00267580, and our registered office is at: 90,Gangarama Mw, Pelena, Weligama, Sri Lanka. </p>
                        <p>9.3  You can contact us:</p>
                        <div className='flex flex-col gap-3'>
                            <p className='text-sm'>(a) by post, using the postal address given above;</p>
                            <p className='text-sm'>(b) using our website contact form;</p>
                            <p className='text-sm'>(c) by telephone, on +94 113483727 or</p>
                            <p className='text-sm'>(d) by email, using info@cozybusy.com</p>
                        </div>
                    </div>


                </div>

            </div>

            <CookiePolicyNotification />

            <Footer />

        </>
    )
}

export default PrivacyPolicy
