import React from 'react';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import CookiePolicyNotification from '../components/CookiePolicyNotification';
import { ArrowUpOutlined } from '@ant-design/icons';

const GDRP = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Add smooth scrolling behavior
    });
  };

  return (
    <>

      <div className='fixed z-50 md:bottom-5 md:right-5 right-2 bottom-2'>
        <button onClick={scrollToTop} className='bg-[#9CCD58] rounded-full shadow-xl w-10 h-10 active:scale-105'><ArrowUpOutlined size={30} className='text-white' /> </button>
      </div>

      <Navbar />
      <div className='flex justify-center w-full h-full '>

        <div className=' w-[900px] flex flex-col text-start gap-5  md:p-10 shadow-xl p-5 my-5'>
          <h1 className='text-xl font-medium'>GDPR</h1>
          <h1 className='text-lg'>What is the GDRP</h1>
          <div className='flex flex-col gap-6'>
            <p>The GDPR is the new European legislation meant to replace the 1995 Data Protection Directive</p>
            <p>This regulation, which can be read in full on the CNIL website, is the new European law governing the use and handling of individuals’ personal data.</p>
            <p>It has 3 primary objectives:</p>
            <div className='flex flex-col gap-2'>
              <p className='text-sm'>1.	To standardize European data protection regulations.</p>
              <p className='text-sm'>2.	To give citizens control over how their personal data is used.</p>
              <p className='text-sm'>3.	To make sure that companies are aware of their responsibilities regarding personal data</p>
            </div>
            <p>What measures has Cozybusy taken to be in compliance with the GDPR?</p>
            <p>As a Cozybusy customer, the GDPR gives you new protection rights and assures better access to your personal data.</p>
            <p>Right to rectification: Rectify your personal information at any time from your account settings. You can also contact us directly to edit or rectify your information.</p>
            <p>Right to be forgotten: Cancel your Cozybusy subscription and close your account at any time. You can send us a request to erase all your data, which we will complete within 30 days.</p>
            <p>Right to portability: Upon request, we will export your data so that it can be transferred to a third party or competitor.</p>
            <p>Right to object: Unsubscribe at any time to any specific use of your information (newsletter, automatic emails, etc.).</p>
            <p>Right of access: We are transparent about the data that we collect and what we do with it. To familiarize yourself with this, please refer to our privacy policy. You can contact us at any time to access and modify any of your personal data.</p>
            <p>The GDPR provides new rights for your users and customers as well. Thanks to the measures that Cozybusy has taken to be compliant with the new regulations, you will be able to answer any requests from users who are looking to exercise their new rights regarding their personal data stored in your database.</p>
            <p><b>Right to rectification:</b> You can rectify your contacts’ information at any time. You can also contact us directly to ask us to rectify or delete your data.</p>
            <p><b>Right to be forgotten:</b> If one of your contacts wishes to exercise their right to be forgotten, you can simply delete them from your lists in Cozybusy. This will also erase all their personal data. If one of your contacts sends a valid request directly to us, we will inform you and remove their personal data from your account, as well as from any other Cozybusy accounts who have personal data on this contact, when applicable.</p>
            <p><b>Right to portability:</b> You can already export your contacts information as a csv file.</p>
            <p><b>Right of access:</b> Make sure that you explain in your privacy policy how you plan to use the personal data that you collect. If your customers make a request to exercise their right of access, you can export their personal data to a csv file (see right to portability).</p>

          </div>

          <h1 className='text-lg'>How can you ensure that you’re in compliance with the GDPR?</h1>

          <div className='flex flex-col gap-6'>

            <div className='flex flex-col gap-3'>
              <p>1. Learn how to rectify and delete your contacts’ information</p>

              <p className='text-sm'>The right to access, modify and delete data is one of the key points of the GDPR. Find out how to delete contacts or modify their information.</p>
            </div>


            <div className='flex flex-col gap-3'>
              <p>2. Update your subscription forms</p>
              <div className='flex flex-col gap-2'>
                <p className='text-sm'>The We recommend that you study and update the wording of your subscription forms so that they are as explicit as possible regarding how requested information will be used. Include affirmative language that clearly states the user agrees to the stated terms.</p>
                <p className='text-sm'>We also recommend using a different opt-in for each of the different ways you plan to use personal data from users. For example, you should use two separate opt-in forms when gathering subscribers for a newsletter and subscribers to receive automated emails triggered by specific user actions (i.e. marketing automation).</p>
              </div>
            </div>

            <div className='flex flex-col gap-3'>
              <p>3. Delete the contacts and lists you no longer need</p>
              <div className='flex flex-col gap-2'>
                <p className='text-sm'>One of the main objectives of the GDPR is to minimize the risks of data breaches or leaks and prevent the misuse of European residents’ personal data.</p>
                <p className='text-sm'>This is why it is better to delete all of your contacts who are inactive or those who have unsubscribed to your communications already. If you are not using this information, then it is more advisable to discard it.</p>
              </div>
            </div>

            <div className='flex flex-col gap-3'>
              <p>4. Consult with your legal counsel</p>
              <div className='flex flex-col gap-2'>
                <p className='text-sm'>The information on this page is designed to help prepare Cozybusy users for the GDPR in the context of our services and should not be taken as legal advice. Additionally, there may be parts of the legislation that affect other aspects of your business as well.</p>
                <p className='text-sm'>We recommend you seek qualified legal counsel to determine what compliance measures you need to carry out to be fully compliant with the GDPR.</p>
              </div>
            </div>
            
          </div>

        </div>

      </div>

      <CookiePolicyNotification />
      <Footer />
    </>
  )
}

export default GDRP
