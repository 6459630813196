import React from 'react'
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";

import Location from '../assets/location.png'
import Phone from '../assets/phone.png'

import Phone2 from '../assets/phone2.png'
import Message2 from '../assets/message2.png'
import Insta from '../assets/insta.png'
import Facebook from '../assets/facebook.png'
import Linkedin from '../assets/linked.png'

import ContactLocation from '../components/ContactLocation';
import CookiePolicyNotification from '../components/CookiePolicyNotification';

import { Helmet } from 'react-helmet';

import { ArrowUpOutlined } from '@ant-design/icons';

const Contact = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    };

    return (
        <div>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Get in Touch with CozyBusy™ - We're Here to Help</title>
                <meta name="description" content="Have questions or want to learn more about CozyBusy™? Contact us today! Our team is ready to assist you with any inquiries about our organic product range, sustainability practices, or how you can join the conscious consumer movement. Reach out to us for collaboration opportunities or to become a part of our global community." />
            </Helmet>

            <div className='fixed z-50 md:bottom-5 md:right-5 right-2 bottom-2'>
                <button onClick={scrollToTop} className='bg-[#9CCD58] rounded-full shadow-xl w-10 h-10 active:scale-105'><ArrowUpOutlined size={30} className='text-white' /> </button>
            </div>

            <Navbar />

            <div className='flex flex-col items-center justify-center'>

                {/*  */}
                <div className='w-full px-3 py-10 lg:w-2/3 md:w-3/4 md:px-0'>
                    <h1 className='pb-10 text-3xl font-medium uppercase'>Contact Us</h1>

                    <form method='POST' action='https://getform.io/f/cc7dedf6-4778-4f70-8f5d-ce0b874c745d' className='flex flex-col gap-6'>
                        <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
                            <div className='flex flex-col gap-3'>
                                <div className='flex gap-2'>
                                    <span className='text-lg' fo>First Name</span>
                                    <span className='text-red-500'>*</span>
                                </div>
                                <input type='text' className='py-2 bg-[#dbecd5] outline-none px-5' name="First Name" required />
                            </div>
                            <div className='flex flex-col gap-3'>
                                <div className='flex gap-2'>
                                    <span className='text-lg'>Last Name</span>
                                    <span className='text-red-500'>*</span>
                                </div>
                                <input type='text' className='py-2 bg-[#dbecd5] outline-none px-5' name="Last Name" required />
                            </div>
                        </div>

                        <div className='flex flex-col gap-3'>
                            <div className='flex gap-2'>
                                <span className='text-lg'>Email Address</span>
                                <span className='text-red-500'>*</span>
                            </div>
                            <input type='email' className='py-2 bg-[#dbecd5] outline-none px-5' name="Email Address" required />
                        </div>

                        <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
                            <div className='flex flex-col gap-3'>
                                <span className='text-lg'>Telephone</span>
                                <input type='text' className='py-2 bg-[#dbecd5] outline-none px-5' name="Telephone" />
                            </div>

                            <div className='flex flex-col gap-3'>
                                <span className='text-lg'>Reasons</span>
                                <select className='py-2 bg-[#dbecd5] outline-none px-5' name='Reason'>
                                    <option>Select your Reason</option>
                                    <option>Product Inquiry</option>
                                    <option>Become a Vendor</option>
                                    <option>Sample request</option>
                                    <option>General</option>
                                </select>
                            </div>
                            
                        </div>


                        <div className='flex flex-col gap-3'>

                            <span className='text-lg'>Message</span>

                            <textarea type='text' className='py-2 bg-[#dbecd5] outline-none px-5 h-32' name="Message"></textarea>
                        </div>

                        <div className='flex items-center justify-end w-full'>
                            <button className='bg-[#A9CD58] px-12 py-2 hover:bg-[#87a442]'>Submit</button>
                        </div>

                    </form>

                </div>

                {/*  */}


                <div className='grid w-full grid-cols-1 px-3 py-0 pb-10 md:py-20 lg:w-2/3 md:w-4/5 md:grid-cols-2 md:px-0 '>
                    <div className='flex flex-col'>
                        <h1 className='text-2xl text-[#9ccd58] pb-8'>SRI LANKA </h1>

                        <div className='flex flex-col gap-10'>
                            <div className='flex gap-6'>
                                <div>
                                    <img src={Location} alt="/" className='w-[40px]' />
                                </div>
                                <div className='flex flex-col gap-6'>
                                    <div className='flex flex-col gap-3'>
                                        <h1 className='text-lg font-medium'>Factory:</h1>
                                        <div className='flex flex-col'>
                                            <span>90,Gangarama Mawatha,</span>
                                            <span> Pelena, Weligama. </span>
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-3'>
                                        <h1 className='text-lg font-medium'>Office:</h1>
                                        <div className='flex flex-col'>
                                            <span>Brownshill Terace Mawatha </span>
                                            <span>Browns Hill, Matara 81000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex gap-6'>
                                <div>
                                    <img src={Phone} alt="/" className='w-[40px]' />
                                </div>
                                <div>
                                    <span>+94 766 549 519 | +94 722 419 520</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-10 md:mt-0'>
                        <ContactLocation />
                    </div>
                </div>

                <div className='flex flex-col w-full gap-12 px-3 pb-10 mt-10 md:w-4/5 lg:w-2/3 md:mt-0'>

                    {/*  */}
                    <div className='flex flex-col gap-4'>
                        <h1 className='text-xl text-[#9ccd58] border-b pb-5'>Europe</h1>


                        <div className='grid grid-cols-1 gap-5 md:grid-cols-2'>
                            <div className='flex gap-3'>
                                <img src={Location} alt="/" className='w-[40px] h-[40px]' />
                                <div className='flex flex-col'>
                                    <span>M/s. FRESCO EUROPE GMBH Langer Kornweg 32, </span>
                                    <span> 65451 Kelsterbach, Germany</span>
                                </div>
                            </div>
                            <div className='flex justify-start gap-3 md:justify-end'>
                                <img src={Phone} alt="/" className='w-[40px] h-[40px]' />
                                <div className='flex flex-col'>
                                    <span>+49 1718060653</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  */}
                    <div className='flex flex-col gap-4'>
                        <h1 className='text-xl text-[#9ccd58] border-b pb-5'> UK</h1>


                        <div className='grid grid-cols-1 gap-5 md:grid-cols-2'>
                            <div className='flex gap-3'>
                                <img src={Location} alt="/" className='w-[40px] h-[40px]' />
                                <div className='flex flex-col'>
                                    <span>Kacha Bazar Lozells,
                                        168,</span>
                                    <span>Lozells Road,
                                        Birmingham, B19 2SX United Kingdom </span>
                                </div>
                            </div>
                            <div className='flex justify-start gap-3 md:justify-end'>
                                <img src={Phone} alt="/" className='w-[40px] h-[40px]' />
                                <div className='flex flex-col'>
                                    <span>+44 7895617222</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  */}
                    <div className='flex flex-col gap-4'>
                        <h1 className='text-xl text-[#9ccd58] border-b pb-5'>USA</h1>


                        <div className='grid grid-cols-1 gap-5 md:grid-cols-2'>
                            <div className='flex gap-3'>
                                <img src={Location} alt="/" className='w-[40px] h-[40px]' />
                                <div className='flex flex-col'>
                                    <span>30, Eastchester  Road,
                                        Apt 3E,  New Rochelle,

                                    </span>
                                    <span> NY, USA. 10801</span>
                                </div>
                            </div>
                            <div className='flex justify-start gap-3 md:justify-end'>
                                <img src={Phone} alt="/" className='w-[40px] h-[40px]' />
                                <div className='flex flex-col'>
                                    <span>+1 914 520 9191 </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='grid w-full grid-cols-1 gap-6 px-3 py-10 md:grid-cols-2 md:gap-16 md:w-4/5 lg:w-2/3 md:px-0'>

                    {/*  */}
                    <div className='flex flex-col items-center justify-center gap-4 p-10 bg-[#dbecd5] shadow-md'>
                        <img src={Message2} alt="" />
                        <span className='text-blue-600 cursor-pointer'>info@cozybusy.com</span>
                        <span className='text-blue-600 cursor-pointer'>cozybusyexport@gmail.com</span>
                    </div>

                    {/*  */}
                    <div className='flex flex-col items-center justify-center gap-4 p-10 bg-[#dbecd5] shadow-md'>
                        <img src={Phone2} alt="" />
                        <span>+94 766 549519 </span>
                        <span>+94 11 3483727</span>
                    </div>
                </div>

                <div className='flex flex-col w-full gap-6 px-3 py-6 md:w-4/5 lg:w-2/3 bg-[#dbecd5] mb-10 shadow-xl'>
                    <div className='text-center'>
                        <h1 className='text-3xl font-medium'>Follow Us </h1>
                    </div>
                    <div className='flex items-center justify-center gap-16'>

                        <a href='https://www.instagram.com/cozybusylife'>
                            <img src={Insta} alt="/" className='w-[40px]' />
                        </a>

                        <a href='https://web.facebook.com/cozybusy'>
                            <img src={Facebook} alt="/" className='w-[40px]' />
                        </a>

                        <a href='https://www.linkedin.com/company/cozybusy'>
                            <img src={Linkedin} alt="/" className='w-[40px]' />
                        </a>
                    </div>
                </div>
            </div>

            <CookiePolicyNotification />

            <Footer />
        </div>
    )
}

export default Contact
