import React from 'react'

import Certificate1 from '../assets/certifi1.png'
import Certificate2 from '../assets/certifi2.jpg'
import Certificate3 from '../assets/certifi3.png'
import Certificate4 from '../assets/certifi4.png'
import Certificate5 from '../assets/certifi5.jpg'
import Certificate6 from '../assets/certifi6.png'

const HomeItem6 = () => {
    return (
        <div className='px-6 pb-10 bg-white lg:px-20 md:px-12'>

            {/*  */}
            <div className='py-10 '>
                <h1 className='uppercase md:tracking-[5px] md:text-3xl font-bold text-2xl tracking-[2px]'>Our Certifications</h1>
            </div>

            <div>
                <p>Good News! We work exclusively with certified factories and partners. This means your trust is backed by rigorous quality standards, ensuring your satisfaction and peace of mind. We believe in delivering not just products, but also confidence in the reliability of our service.
                    Your partnership with us means you're choosing a dedicated commitment to excellence and the highest quality standards in the industry.
                </p>
            </div>


            <div className='grid  border-[1px] lg:gap-28 md:gap-10 md:grid-cols-6 gap-12 shadow-lg mt-10 p-5 grid-cols-3'>
                <div className='flex items-center justify-center'>
                    <img src={Certificate1} alt='/' />
                </div>
                <div className='flex items-center justify-center'>
                    <img src={Certificate2} alt='/' />
                </div>
                <div className='flex items-center justify-center'>
                    <img src={Certificate3} alt='/' />
                </div>
                <div className='flex items-center justify-center'>
                    <img src={Certificate4} alt='/' />
                </div>
                <div className='flex items-center justify-center'>
                    <img src={Certificate5} alt='/' />
                </div>
                <div className='flex items-center justify-center'>
                    <img src={Certificate6} alt='/' />
                </div>
                 
            </div>

            <h1 className='flex flex-col pt-10 font-medium lg:gap-3 md:text-lg lg:flex-row'>Curious to know more? Get in touch with us at <p className='text-blue-700 cursor-pointer'> info@cozybusy.com</p> !  We would love to hear from you!</h1>

        </div>
    )
}

export default HomeItem6
