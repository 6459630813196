import React from 'react'
import { FaFacebook, FaPhone, FaInstagram, FaLinkedin } from "react-icons/fa";
import { BsEnvelope } from "react-icons/bs";
import Logo from "../assets/COZYBUSY-BLACK-LOGO-02.png";
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='py-10 lg:px-28 bg-[#ececec] md:px-12 px-6'>
            <div className='grid lg:grid-cols-2 md:grid-cols-1'>

                {/*  */}
                <div className='flex flex-col lg:pr-20'>

                    {/* <h1 className='lg:text-6xl Jospin-text tracking-[10px] md:text-5xl text-4xl'>COZYBUSY</h1> */}

                    <div>
                        <img src={Logo} alt="/" className='h-[120px] -mt-4 -ml-4' />
                    </div>

                    {/* <span className='lg:Jospin-text lg:tracking-[2px] text-sm md:tracking-[1px] Jospin-text'>Experience the tropical bliss of our premium.</span> */}

                    <p className='text-sm md:text-base lg:text-lg'>COZYBUSY™ is a Sri Lankan company that was founded in 2022. We are proud to support Sri Lankan farmers and businesses. We also believe in giving back to the community. That's why we donate a portion of our profits to charities that support sustainable agriculture and healthy living.
                        We hope you'll join us in our pursuit of a healthier and more sustainable future.
                    </p>
                </div>

                {/*  */}
                <div className='grid grid-cols-1 mt-6 md:mt-10 lg:mt-0'>

                    {/*  */}
                    <div className='grid grid-cols-2'>

                        <div className='flex flex-col gap-1 lg:px-10 Inter-text md:px-2'>
                            <h1 className='pb-2 text-lg font-medium md:text-xl md:pb-4 Inter-text'>Business with us</h1>

                            <span className='cursor-pointer hover:text-[blue]'>Franchiser option</span>
                            <span className='cursor-pointer hover:text-[blue]'>Become a Vendor</span>
                            <span className='cursor-pointer hover:text-[blue]'>Become a reseller</span>
                        </div>

                        <div className='flex flex-col gap-1 lg:px-10 Inter-text md:px-2'>
                            <h1 className='pb-2 text-lg font-medium md:text-xl md:pb-4 Inter-text'>Get involve with us</h1>

                            <span className='cursor-pointer hover:text-[blue]'>Factory tours</span>
                            <span className='cursor-pointer hover:text-[blue]'>Community Programs</span>
                            <span className='cursor-pointer hover:text-[blue]'>Social Media Supporters</span>
                        </div>

                    </div>

                    {/*  */}
                    <div className='grid grid-cols-2 mt-10'>

                        <div className='flex flex-col gap-1 lg:px-10 Inter-text md:px-2'>
                            <h1 className='pb-2 text-lg font-medium md:text-xl md:pb-4 Inter-text'>Partner with us</h1>

                            <span className='cursor-pointer hover:text-[blue]'>Investors</span>
                            <span className='cursor-pointer hover:text-[blue]'>Value Additions</span>
                            <span className='cursor-pointer hover:text-[blue]'>Farmers</span>
                        </div>

                        <div className='flex flex-col gap-1 lg:px-10 Inter-text md:px-2'>
                            <h1 className='pb-2 text-lg font-medium md:text-xl md:pb-4 Inter-text'>Contact us</h1>

                            <div className='flex flex-col gap-3'>

                                <div className='flex items-center gap-3 cursor-pointer'>
                                    <FaPhone /><span className='hover:text-[blue]'>+94 766 549519 </span>
                                </div>
                                <div className='flex items-center gap-3 cursor-pointer'>
                                    <BsEnvelope /><span className='hover:text-[blue]'>info@cozybusy.com</span>
                                </div>

                                <a href='https://web.facebook.com/cozybusy'>
                                    <div className='flex items-center gap-3 cursor-pointer'>
                                        <FaFacebook /><span className='hover:text-[blue]'>Facebook</span>
                                    </div>
                                </a>

                                <a href='https://www.instagram.com/cozybusylife'>
                                    <div className='flex items-center gap-3 cursor-pointer'>
                                        <FaInstagram /> <span className='hover:text-[blue]'>Instagram</span>
                                    </div>
                                </a>

                                <a href='https://www.linkedin.com/company/cozybusy'>
                                    <div className='flex items-center gap-3 cursor-pointer'>
                                        <FaLinkedin /> <span className='hover:text-[blue]'>LinkedIn</span>
                                    </div>
                                </a>

                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <div className='flex-col justify-center pt-16 md:hidden'>
                <div className='flex justify-center gap-[3px]'>
                    <span>CozyBusy (Pvt) Ltd - 2023 </span>
 

                </div>
                <div className='flex justify-center gap-2'>
                    <NavLink
                        exact
                        to="/Privacy_Policy"><a href='#' className='cursor-pointer hover:text-[blue]'>Privacy_Policy </a></NavLink>
                    <span>|</span>
                    <NavLink
                        exact
                        to="/GDRP"><a href='#' className='cursor-pointer hover:text-[blue]'>GDRP </a></NavLink>
                    <span>|</span>
                    <NavLink
                        exact
                        to="/Cookies_Policy">
                        <a href='#' className='cursor-pointer hover:text-[blue]'>Cookies_Policy</a></NavLink>
                </div>
            </div>

            <div className='justify-center hidden gap-3 pt-16 md:flex'>

                <span>CozyBusy (Pvt) Ltd - 2023 </span>
                <span>|</span>
 

                <NavLink
                    exact
                    to="/Privacy_Policy">
                    <a href='#' className='cursor-pointer hover:text-[blue]'>Privacy_Policy </a></NavLink>
                <span>|</span>
                <NavLink
                    exact
                    to="/GDRP"><a href='#' className='cursor-pointer hover:text-[blue]'>GDRP </a></NavLink>
                <span>|</span>
                <NavLink
                    exact
                    to="/Cookies_Policy">
                    <a href='#' className='cursor-pointer hover:text-[blue]'>Cookies_Policy</a></NavLink>

            </div>

        </div>
    )
}

export default Footer
