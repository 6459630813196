import React, { useState } from "react";
import { BsSearch} from "react-icons/bs";

import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { NavLink } from 'react-router-dom';

import User from "../assets/user.png";

const Navbar = () => {

  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex items-center justify-between mx-auto  w-full bg-[#02020293] py-2">
      {/* menu */}
      <ul className="hidden gap-6 px-5 text-xl Inter-text lg:flex">
        <NavLink
          exact
          to="/">
          <li className="flex items-center px-6 py-4 text-white duration-200 hover:text-green-300 hover:font-medium">
            Home
          </li>
        </NavLink>
        <NavLink exact
          to="/about">
          <li className="flex items-center px-6 py-4 text-white duration-200 hover:text-green-300 hover:font-medium">
            About Us
          </li>
        </NavLink>
        <NavLink exact
          to="/our_story">
          <li className="flex items-center px-6 py-4 text-white duration-200 hover:text-green-300 hover:font-medium">
            Our Story
          </li>
        </NavLink>
        <li className="flex items-center px-6 py-4 text-white duration-200 hover:text-green-300 hover:font-medium">
          Product
        </li>

      </ul>

      <div onClick={handleNav} className="block pl-6 lg:hidden">

        <AiOutlineMenu size={30} className="text-white duration-200 hover:text-green-300 hover:font-medium" />

      </div>


      <div className="flex md:pr-5">
        <ul className="hidden gap-10 text-xl Inter-text lg:flex">
          <NavLink exact
            to="/contact"
            activeClassName="text-black">
            <li className="flex items-center px-6 py-4 text-white duration-200 hover:text-green-300 hover:font-medium">
              Contact
            </li>
          </NavLink>
        </ul>

        {/*  */}
        <div className="py-5 md:px-5">
          <BsSearch className="text-white cursor-pointer md:text-4xl" size={30} />
        </div>
        <div className="px-6 py-4 md:px-5">
          <img src={User} alt="/" className="w-[40px]" />
        </div>

      </div>

      <div
        className={nav ? 'top-0 left-0 flexed md:w-[60%]  w-[80%] border-r border-r-gray-900 h-full fixed    bg-black/90 ease-in-out duration-500 z-50' : 'fixed w-[80%] left-[-100%] h-full ease-in-out duration-500 top-0 z-50'}
      >
        <div className="flex">
          <h1 className="w-full m-5 text-3xl font-bold text-white">
            Cozybusy
          </h1>

          <div onClick={handleNav} className="block pt-5 pr-5 lg:hidden">

            <AiOutlineClose size={25} className="text-white " />

          </div>
        </div>

        <ul className="p-4 uppercase mt-14 Inter-text">
          <NavLink to="/"><li className="flex items-center p-4 text-white border-gray-600">
            Home
          </li> </NavLink>
          <NavLink to="/about"><li className="flex items-center p-4 text-white border-gray-600">
            About Us
          </li> </NavLink>
          <NavLink exact
            to="/our_story">
            <li className="flex items-center p-4 text-white border-gray-600">
              Our Story
            </li>
          </NavLink>
          <li className="flex items-center p-4 text-white border-gray-600">
            Product
          </li>
          <NavLink exact
            to="/contact">
            <li className="flex items-center p-4 text-white border-gray-600">
              Contact
            </li></NavLink>
        </ul>
      </div>
    </div>
  )
}

export default Navbar
