import React, { useState,useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { hasCookie,setCookie } from 'cookies-next';


 

function CookiePolicyNotification() {

    const [showConsent,setShowConsent] = useState(true);

    useEffect(() =>
    {
        setShowConsent(hasCookie("localConsent"));
    },[]);

    const acceptCookie = () =>{
        setShowConsent(true);
        setCookie("localConsent","true",{})
    };

    if(showConsent){
        return null;
    }

 

    return (
    
            <> 
                <div className="fixed bottom-0 z-50 w-full p-5 text-white bg-gray-800">
                    <div className="container mx-auto">
                        <div className="flex flex-col items-center justify-between lg:flex-row">
                            <p>By continuing to browse this site, You agree to the of cookies to improve your user experience. Please visit our
                                <NavLink
                                    exact
                                    to="/Cookies Policy" className={`text-blue-600`}>   cookie policy</NavLink> for more information.   </p>
                            <div className='flex gap-5 mt-5 lg:mt-0'>

                                <button

                                    onClick={() => acceptCookie()}
                                    className="px-4 py-2 font-bold text-white rounded outline outline-[#8CC63F] hover:bg-[#8CC63F] duration-300 hover:text-black"
                                >
                                    Accept all cookies
                                </button>


                          
                            </div>
                        </div>
                    </div>
                </div>
            </>
       
    );
}

export default CookiePolicyNotification;
