import Home from "../src/Pages/Home";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import StoryPage from "./Pages/StoryPage";
import Cookies from "./Pages/Cookies";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import GDRP from "./Pages/GDRP";
 

function App() {
  return (
    <div className="App scroll-smooth">
    
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
         
          </Route> 
          <Route path="/about" element={<About />}>
            
          </Route> 
          <Route path="/contact" element={<Contact />}>
       
          </Route> 
          <Route path="/our_story" element={<StoryPage />}>
           
          </Route> 
          <Route path="/Cookies_Policy" element={<Cookies />}>
             
          </Route> 
          <Route path="/Privacy_Policy" element={<PrivacyPolicy />}>
            
          </Route> 
          <Route path="/GDRP" element={<GDRP/>}>
            
            </Route> 
        </Routes> 
      </BrowserRouter> 
    </div>
  );
}

export default App;
