import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import StoryPageItem2 from '../components/StoryPageItem2'
import CookiePolicyNotification from '../components/CookiePolicyNotification'

import { Helmet } from 'react-helmet';

import { ArrowUpOutlined } from '@ant-design/icons';

const StoryPage = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    };

    return (
        <div className=''>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Our Story: CozyBusy™'s Journey to Conscious Consumerism</title>
                <meta name="description" content="Explore the roots of CozyBusy™, from its inception to becoming a leader in organic and eco-friendly products. Our story is a testament to our commitment to women's empowerment, community development, and sustainable growth. Learn how our founders' vision and expertise shape our unique approach to providing healthy, premium-quality products." />
            </Helmet>

            <div className='fixed z-50 md:bottom-5 md:right-5 right-2 bottom-2'>
                <button onClick={scrollToTop} className='bg-[#9CCD58] rounded-full shadow-xl w-10 h-10 active:scale-105'><ArrowUpOutlined size={30} className='text-white' /> </button>
            </div>

            <Navbar />
            {/* <StoryPageItem1 /> */}
            <StoryPageItem2 />
            <CookiePolicyNotification />
            <Footer />
        </div>
    )
}

export default StoryPage
