import React from 'react'
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";

import Image from '../assets/cozyteam.png'
import LinkedIn from '../assets/linkedin.png'

import Chanda from '../assets/mrs.chandani.jpg'
import Ehan from '../assets/eshan(neww).png'
import Roshan from '../assets/roshan(new).png'
import Akbar from '../assets/akbar(new).png'

import CookiePolicyNotification from '../components/CookiePolicyNotification';

import { ArrowUpOutlined } from '@ant-design/icons';

import { Helmet } from 'react-helmet';

const About = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Add smooth scrolling behavior
    });
  };

  
  return (
    <div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Meet CozyBusy™ - Pioneers in Sustainable Food & Beverage Innovation</title>
        <meta name="description" content="Discover the story behind CozyBusy™, a brand dedicated to sustainable practices and conscious consumerism. Learn about our passionate team, led by experts in cooperative community management, digital marketing, and international trade. Our commitment to organic, premium products reflects our dedication to your health and the environment." />
      </Helmet>


      <div className='fixed z-50 md:bottom-5 md:right-5 right-2 bottom-2'>
        <button onClick={scrollToTop} className='bg-[#9CCD58] rounded-full shadow-xl w-10 h-10 active:scale-105'><ArrowUpOutlined size={30} className='text-white' /> </button>
      </div>

      <Navbar />
      <div className=''>

        <div className='flex items-center justify-center gap-4 py-10'>
          <h1 className='text-5xl font-medium'>About</h1>
          <h1 className='text-5xl font-medium text-[#9ccd58]'>Us</h1>
        </div>

        <div className='grid grid-cols-1 px-3 pb-20 md:pt-10 md:px-20 lg:grid-cols-2'>
          <div className='overflow-hidden lg:rounded-r-full'>
            <img src={Image} alt="/" />
          </div>
          <div className='flex flex-col justify-center pt-10 gap-7 lg:px-28 lg:pt-0'>
            <h1 className='text-3xl'>About the company,</h1>
            <p className='text-lg'>COZYBUSY™ challenges us all to rethink our lifestyles and consumer habits, and to develop a mind-set as a ‘conscious consumer’. COZYBUSY™ brings innovation to food and beverage sourcing through a platform and online marketplace under the one private labeling Marketplace.</p>
          </div>
        </div>


        <div className='pb-20'>
          <div className='bg-[#e9e9e9] w-full py-5 px-10'>
            <h1 className='text-5xl'>Our Team</h1>
          </div>

          <div className='grid grid-cols-1 gap-8 px-10 py-10 md:grid-cols-2 bg-[#e8e8e8] lg:grid-cols-4'>

            {/*  */}
            <div className='flex flex-col items-center gap-6 px-5 py-5 bg-white shadow-lg rounded-2xl'>
              <div className='w-[160px] h-[160px] rounded-full bg-[#9ccd58] flex justify-center items-center'>
                <img src={Chanda} alt="/" className='w-[150px] h-[150px] rounded-full shadow-[#9ccd58] shadow-md' />
              </div>
              <h1 className='text-xl font-medium'>Mrs.Chandani Guruge</h1>

              <p className='text-sm text-justify'>Chandani is a certified auditor from Sri Lanka, specializing in cooperative community management and holding a Master's in Commerce. With two years of dedicated involvement in the organic movement, she is the founder and director of Cozybusy Pvt Ltd and serves as a co-founder and manager at Holdings Ltd. With over two decades of experience in cooperative development, community standards building, women's empowerment programs, and community-based projects in Sri Lanka, Chandani is an experienced professional in these fields. "</p>

              <h1 className='text-lg font-medium text-center'>FOUNDER</h1>

              <div className='flex justify-end w-full'>
                <a href='https://www.linkedin.com/in/chandani-guruge-476042175/'>
                  <img src={LinkedIn} alt="/" />
                </a>
              </div>
            </div>

            {/*  */}
            <div className='flex flex-col items-center gap-6 px-5 py-5 bg-white shadow-lg rounded-2xl'>
              <div className='w-[160px] h-[160px] rounded-full bg-[#9ccd58] flex justify-center items-center'>
                <img src={Ehan} alt="/" className='w-[150px] h-[150px] rounded-full shadow-[#9ccd58] shadow-md' />
              </div>
              <h1 className='text-xl font-medium'>Mr. Pradeep Ehan</h1>

              <p className='text-sm text-justify'>Pradeep is a versatile individual with 20 years of experience in the Digital Marketing and Social Media field. He has a demonstrated history of working in multiple industries including IT, Trade and MICE Tourism, and Foreign Education consultation. Mr. Pradeep, an award-winning Sri Lankan entrepreneur, holds a distinguished academic background. He is a Master of Science (M.Sc.) in Cybersecurity and a Bachelor of Computer Science (B.CS.) degree holder. In addition to his academic achievements, he is recognized as the South Asia pioneer project developer for the public-private partnership. </p>

              <h1 className='text-lg font-medium text-center'>CO-FOUNDER</h1>

              <div className='flex justify-end w-full'>
                <a href='https://www.linkedin.com/in/pradeepehan/'>
                  <img src={LinkedIn} alt="/" />
                </a>
              </div>

            </div>

            {/*  */}
            <div className='flex flex-col items-center gap-6 px-5 py-5 bg-white shadow-lg rounded-2xl'>
              <div className='w-[160px] h-[160px] rounded-full bg-[#9ccd58] flex justify-center items-center'>
                <img src={Roshan} alt="/" className='w-[150px] h-[150px] rounded-full shadow-[#9ccd58] shadow-md' />
              </div>
              <h1 className='text-xl font-medium'>Mr. L.P.Roshan</h1>

              <p className='text-sm text-justify'>Roshan, an industry veteran with over a decade of factory supervision experience in South Korea and China, established his educational foundation at St. Thomas School in Matara. He embarked on an impressive journey in the industrial sector, specializing in overseeing chipboard manufacturing processes and boasting a solid track record of contributing to the success of various industrial ventures.  As the Director and Shareholder at Cozybusy, Roshan leads strategic expansion into Cocopeat and organic product exporting, driving sustainable growth and global market reach.</p>

              <h1 className='text-lg font-medium text-center'>DIRECTOR</h1>

              <div className='flex justify-end w-full'>
                <a href='https://www.linkedin.com/in/lproshan/'>
                  <img src={LinkedIn} alt="/" />
                </a>
              </div>

            </div>

            {/*  */}
            <div className='flex flex-col items-center gap-6 px-5 py-5 bg-white shadow-lg rounded-2xl'>
              <div className='w-[160px] h-[160px] rounded-full bg-[#9ccd58] flex justify-center items-center'>
                <img src={Akbar} alt="/" className='w-[150px] h-[150px] rounded-full shadow-[#9ccd58] shadow-md' />
              </div>
              <h1 className='text-xl font-medium'>Mr. M.B. Akbar</h1>
 
              <p className='text-sm text-justify'>Akbar, with over 30 years of experience in International Marketing Export, has a demonstrated history of working in import, logistics, and export industries. His expertise includes a strong background in international business development and promotions, particularly in connecting platforms with strategic partners in the EU, UK, and US markets. At CozyBusy.com, Akbar is dedicated to offering a wide range of high-quality organic products. He welcomes collaboration opportunities to expand their reach and promote sustainability in the global market.</p>
     
              <h1 className='text-lg font-medium text-center'>CEO</h1>

              <div className='flex justify-end w-full'>
                <a href='https://www.linkedin.com/in/akbar-buhary-37874818/'>
                  <img src={LinkedIn} alt="/" />
                </a>
              </div>

            </div>

          </div>
        </div>

      </div>

      <CookiePolicyNotification />

      <Footer />
    </div>
  )
}

export default About
