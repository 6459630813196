import React from 'react'
import Chandani from '../assets/mrs.chandani.jpg'
import FactoryImg1 from '../assets/FactoryImg1.jpg'
import FactoryImg2 from '../assets/FactoryImg2.jpg'
import FactoryImg3 from '../assets/FactoryImg3.jpg'

const StoryPageItem2 = () => {
    return (
        <div>

            {/*  */}
            <div className='w-full px-3 py-10 bg-white lg:px-20 md:px-10'>
                <div className='flex flex-col'>

                    <div className='flex flex-col md:flex-row'>
                        <div className='flex flex-col w-full gap-5 md:w-3/4'>
                            <span className='text-3xl font-medium uppercase tracking-[3px]'>Our Story</span>
                            <p className='px-5 text-xl lg:text-2xl md:px-10'><i>“Our commitment extends to seeking out novel products that can enhance our customers' lives.
                                    We remain dedicated to constant innovation, with our mission being the creation of inspiring products.
                                    In doing so, we aim to encourage more individuals to join us in our pursuit of a healthier and wholesome lifestyle”</i></p>

                            <div className='flex-col justify-end hidden px-10 lg:mt-6 text-end md:flex md:mt-2'>
                                <span>Founder – Mrs. Chandani Guruge </span>
                                <span>(Sri lankan women entrepreneur)</span>
                            </div>
                        </div>
                        <div className='flex items-center justify-center w-full md:w-1/4'>
                            <img src={Chandani} alt="/" />
                        </div>
                    </div>

                    <div className='flex flex-col mt-6 text-center md:hidden'>
                        <span>Founder – Mrs. Chandani Guruge </span>
                        <span>(Sri lankan women entrepreneur)</span>
                    </div>

                </div>
            </div>

            {/*  */}
            <div className='w-full px-3 py-10 bg-white lg:px-20 md:px-10'>
                <div className='grid grid-cols-1 gap-10 md:grid-cols-3'>
                    <div>
                        <img src={FactoryImg1} alt="" className='shadow-xl rounded-2xl' />
                    </div>
                    <div>
                        <img src={FactoryImg2} alt="" className='shadow-xl rounded-2xl' />
                    </div>
                    <div>
                        <img src={FactoryImg3} alt="" className='shadow-xl rounded-2xl' />
                    </div>
                </div>

                <p className='pt-10 text-lg'>COZYBUSY™ is a company that provides genuine, pure, and safe products that are sourced from certified organic
                    farms and plantations. We are committed to sustainable development and are dedicated to seeking out novel
                    products that can enhance our customers' lives. Our mission is to provide inspiring products that encourage
                    more individuals to join us in our pursuit of a healthier and wholesome lifestyle.</p>
            </div>

            {/*  */}
            <div className='flex flex-col w-full gap-10 px-3 pt-5 pb-10 bg-white lg:px-20 md:px-10'>

                <div className='flex flex-col gap-6'>
                    <h1 className='text-3xl font-medium'>Why Choose COZYBUSY™?</h1>
                    <div className='flex flex-col gap-2 md:text-lg'>
                        <p> We only source from certified organic farms and plantations, so you can be sure that our products are free of GMOs, chemical supplements, and pesticides. We are committed to sustainable development and support small-scale farmers. We are constantly innovating and expanding our selection of products. We are dedicated to providing our customers with the best possible experience.</p>
                    </div>
                </div>

                <div className='flex flex-col gap-6'>
                    <h1 className='text-2xl font-medium'>Our Commitment to Quality</h1>
                    <p>We are committed to providing our customers with the highest quality products possible. That's why we only source from
                        certified organic farms, factories and plantations. We also have a rigorous quality control process in place to
                        ensure that our products meet our high standards.</p>
                </div>

                <div className='flex flex-col gap-6'>
                    <h1 className='text-2xl font-medium'>Our Commitment to Sustainability</h1>
                    <p>We are committed to sustainable development. That's why we support small-scale farmers and use sustainable agricultural
                        practices. We also strive to minimize our environmental impact by using recycled and recyclable materials in our packaging.</p>
                </div>

                <div className='flex flex-col gap-6'>
                    <h1 className='text-2xl font-medium'>Our Commitment to Innovation</h1>
                    <p className=''>We are constantly innovating and expanding our selection of products. We are always on the lookout for novel products that
                        can enhance our customers' lives. Our goal is to inspire more people to join us in our pursuit of a healthier and wholesome lifestyle.</p>
                </div>

                <div className='flex flex-col gap-6'>
                    <h1 className='text-2xl font-medium'>Our Commitment to Customer Satisfaction</h1>
                    <p className=''>We are dedicated to providing our customers with the best possible experience. That's why we offer a satisfaction
                        guarantee on all of our products. If you are not happy with your purchase for any reason, simply return it to us for a full refund.</p>
                </div>

        

            </div>


        </div>
    )
}

export default StoryPageItem2
