import React from 'react'
import Map from "../assets/map3.png";

const HomeItem9 = () => {
  return (
    <div className='px-6 pb-10 bg-white lg:px-28 md:px-12'>
      
            {/*  */}
            <div className='py-5'>
                <h1 className='text-2xl font-bold uppercase md:text-3xl'>Our Export & Partner <br/>network</h1>
            </div>
            
            {/*  */}
            <div className='flex justify-center'>
                <img src={Map} alt='/' className='lg:h-[600px] h-full' />
            </div>
    </div>
  )
}

export default HomeItem9
