import React from 'react'
import Footer from "../components/Footer";
import HomeItem1 from "../components/HomeItem1";
import HomeItem2 from "../components/HomeItem2";
import HomeItem3 from "../components/HomeItem3";
import HomeItem4 from "../components/HomeItem4";
import HomeItem6 from "../components/HomeItem6";
import HomeItem7 from "../components/HomeItem7";
import HomeItem9 from "../components/HomeItem9";
import Navbar from "../components/Navbar";
import CookiePolicyNotification from '../components/CookiePolicyNotification';

import { Helmet } from 'react-helmet';
import { ArrowUpOutlined } from '@ant-design/icons';


const Home = () => {
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Add smooth scrolling behavior
    });
  };

  
  return (
    <div className="bg-fixed home_main">

      <Helmet>
        <meta charSet="utf-8" />
        <title>CozyBusy™ - Revolutionizing Healthy Lifestyles with Organic Foods & Beverages</title>
        <meta name="description" content="Welcome to CozyBusy™, where conscious consumption meets premium organic products. Dive into our world of sustainable, coconut-based products, health supplements, and organic spices, all crafted to nourish your body and the planet. Join us in our journey to redefine food and beverage sourcing with our innovative online marketplace." />
      </Helmet>

      <div className='fixed z-50 md:bottom-5 md:right-5 right-2 bottom-2'>
        <button onClick={scrollToTop} className='bg-[#9CCD58] rounded-full shadow-xl w-10 h-10 active:scale-105'><ArrowUpOutlined size={30} className='text-white' /> </button>
      </div>

      <Navbar />
      <HomeItem1 />
      <HomeItem2 />
      <HomeItem3 />
      <HomeItem4 />
      {/* <HomeItem5/> */}
      <HomeItem6 />
      <HomeItem7 />
      <HomeItem9 />
      <CookiePolicyNotification />
      <Footer />
    </div>
  )
}

export default Home
