import React from 'react'
import Img1 from "../assets/catelog/Coconut-product.jpg";
import Img2 from "../assets/catelog/Organic-suppliment-(mobile)---565-x-565.jpg";
import Img3 from "../assets/catelog/Process-food---564-x-564.jpg";
import Img4 from "../assets/catelog/Spices-&-essential-oil---700-x-700.jpg";
 

const HomeItem4 = () => {
    return (
        <div className='px-6 py-6 bg-white lg:py-20 lg:px-20 md:px-12 md:py-12'>

            {/* 1 */}
            <div className='flex flex-col justify-center shadow-xl md:flex-row '>
                <div className='md:w-[40%] flex md:h-[350px] overflow-hidden h-[200px] justify-center'>
                    <img src={Img1} alt='/' className='h-[200px] w-fit md:h-[350px]' />
                </div>

                <div className='flex flex-col  md:w-[60%] lg:px-10 md:pl-10 pb-5'>
                    <h1 className='lg:text-5xl tracking-[4px] lg:pb-10 md:text-4xl md:pb-3 text-2xl py-3 md:py-0 px-3 md:px-0'>Coconut Product</h1>
                    <p className='px-3 pb-2 text-sm md:pb-4 lg:text-lg md:px-0 paraFont2'>Coconut, from the tropical palm tree, offers rich milk and oil for vibrant cuisine. King Coconut water replenishes after workouts. Flour and sugar provide gluten-free baking. Virgin coconut oil stands as a sustainable, nurturing food choice. It's celebrated in beauty for hair care. Versatile across health, food, and cosmetics, coconuts are integral to modern living, offering diverse benefits in a compact, natural package.g</p>

                    <div className='flex justify-center w-full '>
                        <button className='text-lg font-bold text-center text-white py-1 cursor-pointer learnMoreBtn w-[200px] shadow-xl border-[1px] bg-[#9CCD58]'>Learn more</button>
                    </div>
                </div>
            </div>

            {/* 2 */}
            <div className='flex flex-col justify-center mt-10 shadow-xl md:mt-32 md:flex-row'>

                <div className=' md:hidden h-[200px] overflow-hidden flex justify-center'>
                    <img src={Img2} alt='/' className='h-[200px] w-fit' />
                </div>

                <div className='flex flex-col  md:w-[60%] md:px-10 pb-5'>
                    <h1 className='lg:text-5xl tracking-[4px] lg:pb-10 md:text-4xl md:pb-3 text-2xl py-3 md:py-0 px-3 md:px-0'>Organic Supplements</h1>
                    <p className='px-3 pb-2 text-sm md:pb-4 lg:text-lg md:px-0 paraFont2'>COZYBUSY™ Organic supplements are key for work-life balance, nurturing overall well-being with natural, chemical-free nutrients. They boost energy, mental clarity, and physical vitality, aiding in managing a busy lifestyle. These supplements foster a balanced, sustainable approach to work and personal life, promoting thriving health and equilibrium for individuals striving to maintain their well-being amidst life's demands.</p>
                    <div className='flex justify-center w-full '>
                        <button className='text-lg font-bold text-center text-white py-1 cursor-pointer learnMoreBtn w-[200px] shadow-xl border-[1px] bg-[#9CCD58]'>Learn more</button>
                    </div>
                </div>

                <div className='w-[40%]  justify-center hidden md:flex h-[350px] overflow-hidden'>
                    <img src={Img2} alt='/' className='w-fit h-[350px]' />
                </div>

            </div>


            {/* 3 */}
            <div className='flex flex-col items-center justify-center mt-10 shadow-xl md:mt-32 md:flex-row'>
                <div className='md:w-[40%] flex md:h-[350px] overflow-hidden h-[200px] justify-center '>
                    <img src={Img3} alt='/' className='w-fit h-[200px] md:h-[350px] ' />
                </div>

                <div className='flex flex-col  md:w-[60%] lg:px-10 md:pl-10 pb-5'>
                    <h1 className='lg:text-5xl tracking-[4px] lg:pb-10 md:text-4xl md:pb-3 text-2xl py-3 md:py-0 px-3 md:px-0'>Processed Foods</h1>
                    <p className='px-3 pb-2 text-sm md:pb-4 lg:text-lg md:px-0 paraFont2'>COZYBUSY™ Organic processed foods merge health and efficiency for a balanced lifestyle. They're a convenient, well-being-centric choice, curated sans harmful chemicals. These foods, crafted with care, enable a healthy diet amidst hectic schedules. By bypassing extensive meal prep, they offer precious extra time for work, family, and self-care. A solution that values health and time, these foods streamline busy days without compromising on nutrition or wellness.</p>

                    <div className='flex justify-center w-full'>
                        <button className='text-lg font-bold text-center text-white py-1 cursor-pointer learnMoreBtn w-[200px] shadow-xl border-[1px] bg-[#9CCD58]'>Learn more</button>
                    </div>
                </div>
            </div>

            {/* 4 */}
            <div className='flex flex-col items-center justify-center mt-10 shadow-xl md:mt-32 md:flex-row'>

                <div className='flex justify-center md:hidden h-[200px] overflow-hidden'>
                    <img src={Img4} alt='/' className='h-[200px] w-fit' />
                </div>

                <div className='flex flex-col  md:w-[60%] md:px-10 pb-5'>
                    <h1 className='lg:text-5xl tracking-[4px] lg:pb-6 md:text-4xl md:pb-3 text-2xl py-3 md:py-0 mx-3 md:mx-0'>Organic Spices</h1>
                    <p className='px-3 pb-2 text-sm md:pb-4 lg:text-lg md:px-0 paraFont2'>COZYBUSY™ showcases Sri Lanka's organic spices, enriching flavors while championing well-being. These spices—Cinnamon, Cardamom, Nutmeg, Cloves, White Pepper, and Black Pepper—are cultivated sustainably, free from chemicals. Packed with antioxidants and anti-inflammatory properties, they aid digestion. Beyond taste, these spices offer a healthier life. Incorporating them into meals elevates flavors and promotes vitality, celebrating both exquisite tastes and well-being, making them a must-have for any kitchen.</p>

                    <div className='flex justify-center w-full'>
                        <button className='text-lg font-bold text-center text-white py-1 cursor-pointer learnMoreBtn w-[200px] shadow-xl border-[1px] bg-[#9CCD58]'>Learn more</button>
                    </div>
                </div>

                <div className='w-[40%] hidden md:flex h-[350px] overflow-hidden justify-center'>
                    <img src={Img4} alt='/' className='h-[350px] w-fit' />
                </div>

            </div>



        </div>
    )
}

export default HomeItem4
